:root {
  --border-color: rgba(255, 255, 255, 0.12);

  --text-color-1: white;
  --text-color-2: rgba(255, 255, 255, 0.6);
  --text-color-3: rgba(255, 255, 255, 0.12);

  --text-highlight-1: #4cd7f4;

  --text-warning-1: rgba(255, 122, 0, 1);
  --bg-warning-1: rgba(255, 122, 0, 0.12);

  --text-error-1: rgba(252, 10, 82, 1);
  --bg-error-1: rgba(252, 10, 84, 0.12);

  --bg-color-1: #131319;
  --bg-color-2: #212127;

  --bg-highlight-1: rgba(76, 215, 244, 0.04);
}

.dropdown {
  border: 1px solid var(--text-color-1);
  padding: 8px;
  position: relative;
  width: 150px;
  border: 1px solid var(--border-color);
  cursor: pointer;
  border-radius: 6px;
  z-index: auto;
  background: var(--bg-color-1);
}

.dropdown-menu {
  position: absolute;
  background: var(--bg-color-1);
  width: 150px;
  border: 1px solid var(--border-color);
  left: 0px;
  top: 100%;
  max-height: 400px;
  overflow: scroll;
  cursor: pointer;
  z-index: 100;

  > div {
    font-size: 14px;
    color: var(--text-color-2);
    padding: 8px;

    &:hover {
      background-color: var(--bg-color-2);
      color: var(--text-color-1);
    }
  }
}

.dropdown-placeholder {
  font-size: 14px;
  color: var(--text-color-2);
}

.react-datepicker__header {
  background: var(--bg-color-1) !important;
  border: 1px solid var(--border-color) !important;
}

.react-datepicker {
  background: var(--bg-color-1) !important;
  border: 1px solid var(--border-color) !important;
}

.react-datepicker * {
  color: var(--text-color-1) !important;
}

.react-datepicker__day {
  border: 1px solid transparent !important;
  color: var(--text-color-2) !important;
}

.react-datepicker__day:hover {
  background: none !important;
  border: 1px solid var(--text-highlight-1) !important;
  color: var(--text-color-1) !important;
}

.react-datepicker__day--today {
  background: var(--bg-warning-1) !important;
  color: var(--text-warning-1) !important;
  border-radius: 6px !important;
}

.react-datepicker__day--in-selecting-range {
  border: 1px solid var(--text-highlight-1) !important;
  background: transparent !important;
  color: var(--text-color-1) !important;
}

.react-datepicker__day--in-range,
.react-datepicker__day--keyboard-selected {
  background: var(--bg-highlight-1) !important;
  color: var(--text-highlight-1) !important;
}

.react-datepicker__day--disabled {
  color: var(--text-color-3) !important;
}

.react-datepicker__day--disabled:hover {
  border: 1px solid var(--bg-error-1) !important;
  color: var(--text-error-1) !important;
  cursor: not-allowed !important;
}

.react-datepicker-wrapper > div {
  height: 100%;
  display: flex;
}

.react-datepicker-wrapper input {
  padding: 8px;
  margin: auto;
  background-color: black;
  border: 1px solid var(--border-color);
  border-radius: 4px;
  color: white;
  width: 200px;
}

.react-datepicker-popper {
  z-index: 100 !important;
}

.react-datepicker__close-icon::after {
  content: "Clear" !important;
  background: none !important;
  left: 10px !important;
  color: var(--text-highlight-1) !important;
}

.react-datepicker__input-container {
}
